import axios from "axios";

import { loadUser } from "./auth";

export const completeModule = contentId => async dispatch => {
	console.log("hello");
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		await axios.post(`/api/courses/content/complete/${contentId}`, {}, config);
		console.log("updated");
		dispatch(loadUser());
	} catch (err) {
		console.error(err);
	}
};

export const uncompleteModule = contentId => async dispatch => {
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		await axios.post(`/api/courses/content/uncomplete/${contentId}`, {}, config);
		dispatch(loadUser());
	} catch (err) {
		console.error(err);
	}
};
