import React from "react";
import useFetchCourses from "../hooks/useFetchCourses";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";

import { Alert, Card } from "react-bootstrap";

const Courses = ({ auth }) => {
	const { isAuthenticated: isAuth, user } = auth;
	const { courseId } = useParams();
	const { courses, loading } = useFetchCourses(courseId);

	if (loading) {
		return <h1>Loading...</h1>;
	}

	const courseList = courses.map(c => (
		<Card className="course-list-item" key={c.order}>
			<Card.Body>
				<Card.Title className="course-title" style={{ fontWeight: 700 }}>
					<Link to={`/courses/${c.nameId}`}>{c.title}</Link>
				</Card.Title>
				<Card.Text className="course-desc">{c.description}</Card.Text>
			</Card.Body>
		</Card>
	));

	return (
		<div>
			<div className="module-header">
				<h1 className="page-header">Courses</h1>
			</div>
			<div className="container">
				{isAuth && user ? (
					<Alert variant="primary">
						Welcome {user.firstName}. Jump right in where you left off.
					</Alert>
				) : (
					<Alert variant="primary">
						<Alert.Link href="/join">Create an account</Alert.Link> to receive email
						updates and to track your progress.
					</Alert>
				)}
				<div className="courses">{courseList}</div>
			</div>
		</div>
	);
};

export default connect(
	state => ({
		auth: state.auth,
	}),
	{}
)(Courses);
