import React from "react";
function ModuleHeader({ module, shiftModule }) {
	console.log(module.order);

	return (
		<>
			<div className="module-header">
				<div className="flex-container">
					<div className="header-main">
						<h1 className="module-title">
							Module {module.order}: {module.title}
						</h1>
						<p className="module-description">{module.description}</p>
					</div>
					<div className="intro-video">
						<div className="embed-responsive">
							<iframe
								title={`Intro Video for ${module.title}`}
								src={`https://www.youtube.com/embed/${module.introVideo}`}
								frameBorder="0"
								className="embed-responsive-item"
							></iframe>
						</div>
					</div>
				</div>
				<div className="prog-buttons">
					<button onClick={() => shiftModule(-1)} className="prev">
						<i className="fa fa-long-arrow-left" aria-hidden="true"></i>
					</button>
					<button onClick={() => shiftModule(1)} className=" next">
						<i className="fa fa-long-arrow-right" aria-hidden="true"></i>
					</button>
				</div>
			</div>
		</>
	);
}

export default ModuleHeader;
