import { useReducer, useEffect } from "react";
import axios from "axios";

const ACTIONS = {
	MAKE_REQUEST: "make-request",
	GET_DATA: "get-data",
	GET_ALL_MODULES: "get-all-modules",
	ERROR: "error",
};

function reducer(state, action) {
	switch (action.type) {
		case ACTIONS.MAKE_REQUEST:
			return {
				...state,
				loading: true,
				module: {},
			};
		case ACTIONS.GET_ALL_MODULES:
			return {
				...state,
				loading: false,
				module: {},
				modules: action.payload.modules,
				courseInfo: action.payload.courseInfo,
			};
		case ACTIONS.GET_DATA:
			return {
				...state,
				loading: false,
				module: action.payload.module,
				courseInfo: {},
				modules: [],
			};
		case ACTIONS.ERROR:
			return {
				...state,
				loading: false,
				error: action.payload.error,
			};
		default:
			return state;
	}
}

export default function useFetchModules(courseId, moduleNum) {
	const [state, dispatch] = useReducer(reducer, {
		module: {},
		modules: [],
		courseInfo: {},
		loading: true,
	});

	useEffect(() => {
		dispatch({ type: ACTIONS.MAKE_REQUEST });
		if (!courseId) {
			console.log("not attached to a course");
		}
		if (!moduleNum) {
			console.log(`/api/courses/course/${courseId}`);
			axios
				.get(`/api/courses/course/${courseId}`)
				.then(res => {
					console.log(res);
					dispatch({
						type: ACTIONS.GET_ALL_MODULES,
						payload: {
							modules: res.data.modules,
							courseInfo: {
								title: res.data.title,
								description: res.data.description,
							},
						},
					});
				})
				.catch(e => {
					dispatch({ type: ACTIONS.ERROR, payload: { module: e } });
				});
		} else {
			axios
				.get(`/api/courses/course/${courseId}/${moduleNum}`)
				.then(res => {
					console.log(res.data);
					dispatch({ type: ACTIONS.GET_DATA, payload: { module: res.data } });
				})
				.catch(e => {
					dispatch({ type: ACTIONS.ERROR, payload: { module: e } });
				});
		}
	}, [courseId, moduleNum]);

	return state;
}
