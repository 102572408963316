import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ children, auth: { user, isAuthenticated, loading }, ...rest }) => {
	if (!loading && !user) {
		console.log("case 1", loading, user);
		return (
			<Route {...rest}>
				<Redirect to="/login" />
			</Route>
		);
	} else if (!loading && isAuthenticated) {
		console.log("case 2", loading, isAuthenticated);
		return <Route {...rest}>{children}</Route>;
	} else {
		console.log("case 3", loading, isAuthenticated);
		return <h2>Loading...</h2>;
	}
};

export default connect(state => ({ auth: state.auth }))(PrivateRoute);
