import React from "react";
import { useParams, useHistory } from "react-router-dom";
import useFetchModules from "../hooks/useFetchModules";
import { Container } from "react-bootstrap";

import ModuleHeader from "./ModuleHeader";
import ContentListing from "./ContentListing";

const Module = props => {
	const { courseId, moduleId } = useParams();
	const { module, loading, error } = useFetchModules(courseId, moduleId);
	const history = useHistory();
	console.log(module.content);

	const shiftModule = offset => {
		if (module.order + offset <= 0 || module.order + offset >= 11) {
			history.push(`/courses/${courseId}`);
		} else {
			history.push(`/courses/${courseId}/${parseInt(moduleId) + offset}`);
		}
	};

	if (loading) {
		return (
			<Container>
				<h1>Loading...</h1>
			</Container>
		);
	}

	if (error) {
		return <h1>Error. Try refreshing.</h1>;
	}

	return (
		<>
			{error && <h1>Try refreshing.</h1>}
			<ModuleHeader module={module} shiftModule={shiftModule} />
			<ContentListing content={module.content} shiftModule={shiftModule} />
		</>
	);
};

export default Module;
