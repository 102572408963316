import { useReducer, useEffect } from "react";
import axios from "axios";

const ACTIONS = {
	MAKE_REQUEST: "make-request",
	GET_COURSE_DATA: "get-data",
	ERROR: "error",
};

function reducer(state, action) {
	switch (action.type) {
		case ACTIONS.MAKE_REQUEST:
			return {
				...state,
				loading: true,
				courses: [],
			};
		case ACTIONS.GET_COURSE_DATA:
			return {
				...state,
				loading: false,
				courses: action.payload.courses,
			};
		case ACTIONS.ERROR:
			return {
				...state,
				loading: false,
				error: action.payload.error,
			};
		default:
			return state;
	}
}

export default function useFetchCourse(courseId) {
	const [state, dispatch] = useReducer(reducer, {
		courses: [],
		loading: true,
	});

	useEffect(() => {
		dispatch({ type: ACTIONS.MAKE_REQUEST });
		axios
			.get(`/api/courses`)
			.then(res => {
				console.log(res.data);
				dispatch({
					type: ACTIONS.GET_COURSE_DATA,
					payload: { courses: res.data },
				});
			})
			.catch(e => {
				dispatch({ type: ACTIONS.ERROR, payload: { module: e } });
			});
	}, [courseId]);

	return state;
}
