import React, { useEffect } from "react";
import { connect } from "react-redux";

import { clearAuthErrors } from "../actions/auth";
import Alert from "react-bootstrap/Alert";

export const AuthErrors = ({ clearAuthErrors, auth: { errors } }) => {
	useEffect(() => {
		return () => clearAuthErrors();
	}, [clearAuthErrors]);

	const errDisplay = errors.map((err, idx) => (
		<Alert variant="danger" key={idx}>
			{err.msg}
		</Alert>
	));

	return <div className="auth-errors">{errDisplay}</div>;
};

export default connect(
	state => ({
		auth: state.auth,
	}),
	{
		clearAuthErrors,
	}
)(AuthErrors);
