import React from "react";
import { useHistory } from "react-router-dom";
import { Card, Jumbotron } from "react-bootstrap";

const Home = props => {
	const history = useHistory();
	return (
		<>
			<Jumbotron className="home flex-container">
				<div className="home-main">
					<h1>Guided climate change courses.</h1>
					<p>
						Designed for{" "}
						<strong>
							entrepreneurs, climate activists, students, and policymakers{" "}
						</strong>
						alike with interest in climate change and what it will take to fix it.
						Climate Change Academy's courses provide a non-technical entry point to the
						climate space, making getting started accessible with{" "}
						<strong>guided pathways through the best resources</strong> out there in an
						order that makes sense. You’ll get into the important details without any
						inscrutable technical material.
					</p>
					<button className="btn-custom" onClick={() => history.push("/courses")}>
						Get Started
					</button>
				</div>
				<div className="home-video">
					<div className="embed-responsive">
						<iframe
							title={`Intro Video for ${module.title}`}
							src="https://www.youtube.com/embed/SR7WcJtvSuE"
							frameBorder="0"
							className="embed-responsive-item"
						></iframe>
					</div>
				</div>
			</Jumbotron>
			<div className="course-tracks container">
				<h2 className="section-header">Tracks Within Each Course</h2>
				<div className="course-types flex-container">
					<Card className="course-type" border="info">
						<Card.Body>
							<Card.Title>High Flyer</Card.Title>
							<Card.Subtitle className="mb-2 text-muted">3 hours</Card.Subtitle>
							<Card.Text>
								Designed for people looking to understand the big picture of each
								module. Short, informative articles and videos chosen to get you up
								to speed on what people are working on and why.
							</Card.Text>
						</Card.Body>
					</Card>
					<Card className="course-type" border="info">
						<Card.Body>
							<Card.Title>Climate Student</Card.Title>
							<Card.Subtitle className="mb-2 text-muted">20 hours</Card.Subtitle>
							<Card.Text>
								Recommended for people looking to dig deeper into climate solutions
								by watching webinars and listening to podcast by experts,
								scientists, and entrepreneurs in each field.
							</Card.Text>
						</Card.Body>
					</Card>
					<Card className="course-type" border="info">
						<Card.Body>
							<Card.Title>Climate Master</Card.Title>
							<Card.Subtitle className="mb-2 text-muted">35 hours</Card.Subtitle>
							<Card.Text>
								Designed for the true heroes willing to do some heavier lifting to
								really get their arms around climate change and how we can solve it.
								More technical, but still designed to be accessible.
							</Card.Text>
						</Card.Body>
					</Card>
				</div>
			</div>
		</>
	);
};

export default Home;
