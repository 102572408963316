import React from "react";

import Content from "./Content";

function ContentListing({ content, shiftModule }) {
	const sectionTitles = ["High Flyer", "Climate Student", "Climate Master"];
	const sections = sectionTitles.map((title, idx) => {
		return (
			<div className="section" key={idx}>
				<h2 className="section-header">
					<span>{title}</span>
				</h2>
				<div className="content-section">
					{content
						.filter(c => c.level === idx + 1)
						.map(c => (
							<Content key={c._id} content={c} />
						))}
				</div>
			</div>
		);
	});

	return (
		<div className="content-listing">
			<div className="container">{sections}</div>
		</div>
	);
}

export default ContentListing;
