import {
	MAKE_REQUEST,
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	USER_LOADED,
	AUTH_ERROR,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	CLEAR_AUTH_ERRORS,
} from "../actions/types";

const initialState = {
	token: localStorage.getItem("token"),
	isAuthenticated: null,
	loading: false,
	user: null,
	errors: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case MAKE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case USER_LOADED:
			return {
				...state,
				isAuthenticated: true,
				loading: false,
				user: action.payload,
			};

		case REGISTER_SUCCESS:
		case LOGIN_SUCCESS:
			localStorage.setItem("token", action.payload.token);
			return {
				...state,
				...action.payload,
				isAuthenticated: true,
				loading: false,
			};

		case REGISTER_FAIL:
		case LOGIN_FAIL:
		case AUTH_ERROR:
		case LOGOUT:
			localStorage.removeItem("token");
			return {
				token: null,
				isAuthenticated: false,
				loading: false,
				user: null,
				errors: action.payload ? [...action.payload] : [],
			};

		case CLEAR_AUTH_ERRORS:
			return {
				...state,
				errors: [],
			};

		default:
			return state;
	}
}
