import React from "react";
import { Redirect } from "react-router-dom";
import { Formik, Form } from "formik";
import { loginSchema } from "../utils/validateForms";
import FormInput from "../components/FormInput";
import AuthErrors from "../components/AuthErrors";

import { connect } from "react-redux";
import { loginUser } from "../actions/auth";

const Login = ({ loginUser, auth: { isAuthenticated: isAuth } }) => {
	const formValues = {
		email: "",
		password: "",
	};

	if (isAuth) {
		return <Redirect to="/courses" />;
	}

	return (
		<>
			<div className="module-header">
				<h1 className="page-header">Log In</h1>
			</div>
			<div className="container">
				<div className="auth-form">
					<AuthErrors />

					<Formik
						initialValues={formValues}
						validationSchema={loginSchema}
						onSubmit={async values => {
							await loginUser(values);
						}}
					>
						<Form>
							<div className="form-group">
								<FormInput
									name="email"
									label="Email Address"
									element="input"
									type="email"
								/>
								<FormInput
									name="password"
									label="Password"
									element="input"
									type="password"
								/>
							</div>
							<button className="btn btn-custom" type="submit">
								Log In
							</button>
						</Form>
					</Formik>
				</div>
			</div>
		</>
	);
};

export default connect(state => ({ auth: state.auth }), {
	loginUser,
})(Login);
