import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../actions/auth";

import { Navbar, Nav } from "react-bootstrap";

const Header = ({ auth, logoutUser }) => {
	const history = useHistory();
	const { isAuthenticated: isAuth } = auth;
	const guestLinks = (
		<>
			<Nav.Link href="/join">Join</Nav.Link>
			<Nav.Link href="/login">Log In</Nav.Link>
		</>
	);

	const authLinks = (
		<>
			<Nav.Link
				onClick={() => {
					logoutUser();
					history.push("/");
				}}
				href="#!"
			>
				Log Out
			</Nav.Link>
		</>
	);

	return (
		<header>
			<Navbar bg="dark" variant="dark" expand="lg">
				<Navbar.Brand href="/">Climate Change Academy</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
					<Nav>
						<Nav.Link href="/about">About</Nav.Link>
						<Nav.Link href="/courses">Courses</Nav.Link>
						{!isAuth ? guestLinks : authLinks}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</header>
	);
};

export default connect(state => ({ auth: state.auth }), { logoutUser })(Header);
