import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";

const Profile = ({ auth }) => {
	if (!auth.user) {
		return <h2>Loading...</h2>;
	}
	return (
		<div>
			<div className="module-header">
				<h1 className="page-header">Profile</h1>
			</div>
			<Container></Container>
		</div>
	);
};

export default connect(
	state => ({
		auth: state.auth,
	}),
	{}
)(Profile);
