import React from "react";
import { Field, ErrorMessage } from "formik";
import Form from "react-bootstrap/Form";

const FormInput = ({ name, label, element, type }) => {
	return (
		<Form.Group>
			<Form.Label>{label}</Form.Label>
			<Field as={element} id={name} name={name} type={type} />
			<ErrorMessage
				name={name}
				render={msg => <Form.Text className="text-danger">{msg}</Form.Text>}
			/>
		</Form.Group>
	);
};

export default FormInput;
