import React from "react";
import me from "../assets/me.png";

export default function About() {
	return (
		<div className="container">
			<div className="flex-container">
				<div className="about-header">
					<h5>
						Hi everyone! I’m John Sanchez, a rising sophomore (taking leave this coming
						year) at Harvard College studying Computer Science and Chemistry. A few
						months ago I began my journey learning everything I could about carbon
						removal, being fascinated by how it combines so many disciplines and areas
						of thinking and overwhelmed and inspired to action by the impending climate
						crisis. Now I'm branching out, and Climate Change Academy is just getting
						started!
					</h5>

					<p>
						A lot of initially learning about carbon removal for me was wading through
						dense scientific papers and extremely long reports, so my goal here was to
						create an accessible pathway by which people new to the field could learn
						both big picture ideas and nitty gritty details without a technical
						background. To make a truly guided curriculum, I added introduction videos
						to each module to set the stage, approximate time required to go through
						each piece of content, and descriptions underneath each resource to give a
						sense for its value and purpose in the progression.
					</p>
				</div>
				<div className="about-img">
					<img src={me} alt="John Sanchez" />
				</div>
			</div>
			<p>In choosing resources for the curriculum, I looked for content that is:</p>
			<ul>
				<li>
					Accessible and understandable to a non-technical audience without obfuscating
					the important implementation details that make or break climate solutions
				</li>
				<li>Information dense and well-worth time invested to consume</li>
				<li>Well-organized and engaging</li>
			</ul>
			<p>
				Inevitably I missed fantastic resources that deserve to be in the curriculum, and
				perhaps even skipped out on important segments of course topics that need attention.
				Again, Climate Change Academy is just getting started, so if you’re knowledgeable
				about a particular climate topic and would like to help make this the best resource
				for newcomers that it can be, I would really appreciate feedback, advice,{" "}
				<a
					href="https://docs.google.com/forms/d/e/1FAIpQLSdYozcjkR2cT3igtrbzxPIVZVTjI5wkdWfmMEOAycVc1q6Ibg/viewform?usp=sf_link"
					target="_blank"
					rel="noopener noreferrer"
				>
					additional resources to add
				</a>
				, and any other questions or comments that may come up. You can reach me at
				johnsanchez at college dot harvard dot edu.
			</p>
			<p>
				The community I’ve found within carbon removal and climate more broadly, from places
				like the{" "}
				<a href="http://www.airminers.org/" target="_blank" rel="noopener noreferrer">
					Air Miners
				</a>{" "}
				Slack group and beyond, has been extremely welcoming and supportive, making this at
				times crazy learning journey manageable and much more fun. If you’re new to this
				stuff, definitely reach out and join the larger conversation—we’re all definitely
				happy to have you work on this huge problem with us. I’m proud to be a part of this
				community and I can’t wait to see where it goes in the next few years.
			</p>
		</div>
	);
}
