import React from "react";
import { Redirect } from "react-router-dom";
import { Formik, Form } from "formik";
import { registerSchema } from "../utils/validateForms";
import FormInput from "../components/FormInput";
import AuthErrors from "../components/AuthErrors";

import { Container } from "react-bootstrap";

import { connect } from "react-redux";
import { registerUser } from "../actions/auth";

const Register = ({ registerUser, isAuth }) => {
	const formValues = {
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		confirmPassword: "",
	};

	if (isAuth) {
		return <Redirect to="/courses" />;
	}

	return (
		<>
			<div className="module-header">
				<h1 className="page-header">Join Carbon Removal Academy</h1>
			</div>
			<Container className="auth-form">
				<p>
					Create an account to receive occassional updates about new content and site
					features and to track your progress. Please note that registering for an account
					will add you to our email list.
				</p>
				<AuthErrors />
				<Formik
					initialValues={formValues}
					validationSchema={registerSchema}
					onSubmit={async values => {
						await registerUser(values);
					}}
				>
					<Form>
						<div className="form-group">
							<FormInput
								name="firstName"
								label="First Name"
								element="input"
								type="text"
							/>
							<FormInput
								name="lastName"
								label="Last Name"
								element="input"
								type="text"
							/>
							<FormInput
								name="email"
								label="Email Address"
								element="input"
								type="email"
							/>
							<FormInput
								name="password"
								label="Password"
								element="input"
								type="password"
							/>
							<FormInput
								name="confirmPassword"
								label="Confirm Password"
								element="input"
								type="password"
							/>
						</div>
						<button className="btn btn-custom" type="submit">
							Submit
						</button>
					</Form>
				</Formik>
			</Container>
		</>
	);
};

export default connect(
	state => ({
		isAuth: state.auth.isAuthenticated,
	}),
	{ registerUser }
)(Register);
