import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { loadUser } from "./actions/auth";

import "./App.css";
import "remixicon/fonts/remixicon.css";

import { Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import Module from "./components/Module";
import Courses from "./components/Courses";
import CourseOverview from "./components/CourseOverview";
import Header from "./components/Header";
import Register from "./components/Register";
import Login from "./components/Login";
import About from "./components/About";
import PrivateRoute from "./components/PrivateRoute";
import Profile from "./components/Profile";

function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		console.log("here");
		dispatch(loadUser());
	}, [dispatch]);
	return (
		<>
			<Header />
			<Switch>
				<Route exact path="/">
					<Home />
				</Route>
				<Route exact path="/about">
					<About />
				</Route>
				<Route exact path="/join">
					<Register />
				</Route>
				<Route exact path="/login">
					<Login />
				</Route>
				<Route exact path="/courses">
					<Courses />
				</Route>
				<Route exact path="/courses/:courseId">
					<CourseOverview />
				</Route>
				<Route path="/courses/:courseId/:moduleId">
					<Module />
				</Route>
				<PrivateRoute exact path="/profile">
					<Profile />
				</PrivateRoute>
			</Switch>
		</>
	);
}

export default App;
