import React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";

import { connect } from "react-redux";

import { completeModule, uncompleteModule } from "../actions/modules.js";

const Content = ({ content, auth, completeModule, uncompleteModule }) => {
	const done = auth.user && auth.user.completedContent.includes(content._id);

	const getIcon = () => {
		switch (content.type) {
			case "article":
				return "article";
			case "podcast":
				return "mic";
			case "video":
				return "video";
			default:
				return "play";
		}
	};

	const submitComplete = () => {
		completeModule(content._id);
	};

	const submitUncomplete = () => {
		console.log("hi");
		uncompleteModule(content._id);
	};

	return (
		<Row style={{ alignItems: "center" }}>
			<Col xs={1}>
				<div className="icon">
					<i className={`ri-${getIcon()}-fill`}></i>
				</div>
			</Col>
			<Col xs={11}>
				<Card className="border-0">
					<Card.Body>
						<Card.Title style={{ fontWeight: 700 }}>
							<a
								href={content.link}
								target="_blank"
								rel="noopener noreferrer"
								style={{
									textDecoration: done && "line-through",
								}}
							>
								{content.order}. {content.title}
							</a>
						</Card.Title>
						<Row className="justify-content-space-beweetn">
							<Col>
								<Card.Subtitle className="mb-2 text-muted">
									<span className="content-source">{content.source}</span>
								</Card.Subtitle>
							</Col>
							<Col>
								<Card.Subtitle
									style={{ textAlign: "right" }}
									className="mb-2 text-muted"
								>
									<span className="content-time">{content.time}</span>
								</Card.Subtitle>
							</Col>
						</Row>
						<Card.Text>{content.description}</Card.Text>
						{auth.user && (
							<Row className="justify-content-end">
								{!done ? (
									<Button
										variant="primary"
										onClick={() => {
											submitComplete();
										}}
									>
										Complete
									</Button>
								) : (
									<Button
										variant="info"
										onClick={() => {
											submitUncomplete();
										}}
									>
										Un-complete
									</Button>
								)}
							</Row>
						)}
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};

export default connect(
	state => ({
		auth: state.auth,
	}),
	{ completeModule, uncompleteModule }
)(Content);
