import axios from "axios";

import {
	MAKE_REQUEST,
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	USER_LOADED,
	AUTH_ERROR,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	CLEAR_AUTH_ERRORS,
} from "./types";

import setAuthToken from "../utils/setAuthToken";

export const loadUser = () => async dispatch => {
	setAuthToken(localStorage.token);
	dispatch({
		type: MAKE_REQUEST,
	});
	try {
		const res = await axios.get("/api/auth");
		console.log(res);
		dispatch({
			type: USER_LOADED,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: AUTH_ERROR,
		});
	}
};

export const registerUser = user => async dispatch => {
	dispatch({
		type: MAKE_REQUEST,
	});
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};

	const body = JSON.stringify(user);

	try {
		const res = await axios.post("/api/users", body, config);

		dispatch({
			type: REGISTER_SUCCESS,
			payload: res.data,
		});
		dispatch(loadUser());
	} catch (err) {
		dispatch({
			type: REGISTER_FAIL,
			payload: err.response.data.errors,
		});
	}
};

export const loginUser = user => async dispatch => {
	dispatch({
		type: MAKE_REQUEST,
	});
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};

	const body = JSON.stringify(user);

	try {
		const res = await axios.post("/api/auth", body, config);

		dispatch({
			type: LOGIN_SUCCESS,
			payload: res.data,
		});

		dispatch(loadUser());
	} catch (err) {
		dispatch({
			type: LOGIN_FAIL,
			payload: err.response.data.errors,
		});
	}
};

export const clearAuthErrors = () => dispatch => {
	dispatch({ type: CLEAR_AUTH_ERRORS });
};

// Logout / Clear Profile

export const logoutUser = () => dispatch => {
	dispatch({ type: LOGOUT });
};
