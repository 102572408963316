import React from "react";
import useFetchModules from "../hooks/useFetchModules";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";

import { Alert, Card } from "react-bootstrap";

const CourseOverview = ({ auth }) => {
	const { isAuthenticated: isAuth, user } = auth;
	const { courseId } = useParams();

	const { courseInfo, modules, loading } = useFetchModules(courseId, null);

	if (loading) {
		return <h1>Loading...</h1>;
	}
	console.log(modules);
	const moduleList = modules.map(m => (
		<Card className="module-list-item" key={m.order}>
			<Card.Body>
				<Card.Title style={{ fontWeight: 700 }}>
					<Link to={`/courses/${courseId}/${m.order}`}>
						{m.order} - {m.title}
					</Link>
				</Card.Title>
				<Card.Text>{m.description}</Card.Text>
			</Card.Body>
		</Card>
	));

	return (
		<div>
			<div className="module-header">
				<div className="container">
					<h1 className="page-header">{courseInfo.title}</h1>
				</div>
			</div>
			<div className="container">
				{isAuth && user ? (
					<Alert variant="primary">
						Welcome {user.firstName}. Jump right in where you left off.
					</Alert>
				) : (
					<Alert variant="primary">
						<Alert.Link href="/join">Create an account</Alert.Link> to receive email
						updates and to track your progress.
					</Alert>
				)}

				{moduleList}
			</div>
		</div>
	);
};

export default connect(
	state => ({
		auth: state.auth,
	}),
	{}
)(CourseOverview);
