export const MAKE_REQUEST = "MAKE_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_AUTH_ERRORS = "CLEAR_AUTH_ERRORS";

export const COMPLETE_CONTENT = "COMPLETE_CONTENT";
export const MODULE_ERROR = "MODULE_ERROR";
