import * as Yup from "yup";

const registerSchema = Yup.object({
	firstName: Yup.string().required("Please enter your first name"),
	lastName: Yup.string().required("Please enter your last name"),
	email: Yup.string().required("Please enter an email address").email("Invalid email address"),
	password: Yup.string()
		.required("Enter a password")
		.min(8, "Password must be eight characters or more."),
	confirmPassword: Yup.string()
		.required("Please re-enter your password")
		.oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const loginSchema = Yup.object({
	email: Yup.string().required("Please enter an email address").email("Invalid email address"),
	password: Yup.string().required("Enter a password"),
});

export { registerSchema, loginSchema };
